import React from "react";

function TraxStar2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      className="star-below-logo"
      viewBox="0 0 37 37"
    >
      <path
        fill="#487FB2"
        d="M22.862 4.6l-2.018 12.38 11.079 5.882-12.38-2.018-5.882 11.079 2.018-12.38-11.078-5.882 12.38 2.018 5.881-11.078z"
      ></path>
    </svg>
  );
}

export default TraxStar2;