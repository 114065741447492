import React from "react"
import SeoHead from "../../components/global/seoHead"
import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"

import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"
import TraxMain from "../../components/case-studies/trax-mobile-app/traxMain"
import TraxMoreCs from "../../components/case-studies/trax-mobile-app/traxMoreCs"
import DirectContact from "../../components/global/directContact"

import csTraxFeaturedImage from "../../../static/case-studies/trax-mobile-app/trax-featured-thumbnail.png"

import TraxInfo from "../../components/case-studies/trax-mobile-app/traxInfo"
import TraxFullWidth1 from "../../components/case-studies/trax-mobile-app/traxFullWidth1"
import TraxFullWidth2 from "../../components/case-studies/trax-mobile-app/traxFullWidth2"
import TraxUniqueBrand from "../../components/case-studies/trax-mobile-app/traxUniqueBrand"
import TraxFullWidth3 from "../../components/case-studies/trax-mobile-app/traxFullWidth3"
import TraxFullWidth4 from "../../components/case-studies/trax-mobile-app/traxFullWidth4"
import TraxFullWidth5 from "../../components/case-studies/trax-mobile-app/traxFullWidth5"
import TraxFullWidth6 from "../../components/case-studies/trax-mobile-app/traxFullWidth6"

import "../../sass/pages/case-studies/cs-global.scss"
import "../../sass/pages/case-studies/trax-mobile-app.scss"

export const Head = () => (
  <>
    <SeoHead
      ogImage={csTraxFeaturedImage}
      title="Trax Mobile App Case Study | UX/UI Design | eCommerce"
      description="How WowMakers improved usability and overall user experience of Trax, an e-commerce mobile app for e-bike enthusiasts."
    />
  </> 
)

const TraxMobileApp = () => {
    return (
      <Layout>
        <Seo
          bid="trax-mobile-app"
          bclass="case-studies-page"
        ></Seo>
        <ScrollIndicationHeightZero />
        <TraxMain />
        <TraxInfo />
        <TraxFullWidth1 />
        <TraxFullWidth2 />
        <TraxUniqueBrand />
        <TraxFullWidth3 />
        <TraxFullWidth4 />
        <TraxFullWidth5 />
        <TraxFullWidth6 />
        <TraxMoreCs />
        <DirectContact />
      </Layout>
    )
}
export default TraxMobileApp
