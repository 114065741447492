import React from "react"
import TraxUniqueBrandVideo from "../../../images/case-studies/trax-mobile-app/bike-shop-app-unique-brand.mp4"
import { Col, Container, Row } from "react-bootstrap"

const TraxUniqueBrand = () => {
  return (   
    <section className="bike-shop-unique-brand position-relative">
        <div className="bike-shop-animation-right align-items-center justify-content-end d-none d-lg-flex">
            <div className="position-relative">
                <video width="395" height="549" className="vid" autoPlay={true} muted loop>
                    <source src={TraxUniqueBrandVideo} type="video/mp4" />
                </video>
                <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg" className="svg-video-bsu">
                    <path d="M24.3578 6.52658L27.9825 14.5345C31.2245 21.6969 38.6709 25.9961 46.4947 25.2226L55.2421 24.3577L47.2342 27.9824C40.0719 31.2244 35.7726 38.6709 36.5462 46.4946L37.411 55.2421L33.7863 47.2341C30.5443 40.0718 23.0979 35.7726 15.2741 36.5461L6.52664 37.411L14.5346 33.7862C21.6969 30.5443 25.9961 23.0978 25.2226 15.274L24.3578 6.52658Z" fill="#FF6F1F"/>
                </svg>
            </div>
        </div>
        <Container className="position-relative">
            <Row>
                <Col lg={7}>
                    <div className="unique-brand-content position-relative">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="svg-ubc-top">
                            <path d="M15.7031 0.763613L14.0522 10.8911L23.1152 15.703L12.9877 14.0521L8.17586 23.1151L9.82672 12.9876L0.763731 8.17574L10.8912 9.82661L15.7031 0.763613Z" fill="#487FB2"/>
                        </svg>

                        <h2 className="sub-title">Setting a tone that is unique to the brand</h2>
                        <p>
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.5 0L10.8435 8.1565L19 9.5L10.8435 10.8435L9.5 19L8.1565 10.8435L0 9.5L8.1565 8.1565L9.5 0Z" fill="black"/>
                            </svg>
                        </p>
                        <p className="cs-sub-text">During the brainstorming sessions, we felt that a Neo-Pop Design aesthetic would go well with the brand tone. And in the end, we were able to pull off a design and style that would stay unique to the brand.</p>
                        <p className="ubc-border-bottom mt-5"></p>
                        <p className="text-end">
                            <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M34.2768 10.767L37.4045 3.85715L36.6582 11.4051C35.7025 21.0711 41.0141 30.271 49.8629 34.2764L56.7728 37.4041L49.2248 36.6578C39.5588 35.7021 30.3589 41.0137 26.3536 49.8625L23.2259 56.7724L23.9721 49.2244C24.9278 39.5584 19.6163 30.3585 10.7674 26.3532L3.85756 23.2255L11.4055 23.9717C21.0715 24.9274 30.2714 19.6159 34.2768 10.767Z" fill="#FFD223" stroke="black"/>
                            </svg>
                        </p>
                    </div>
                </Col>
                <Col xs={12} className="d-lg-none">
                    <video width="395" height="549" className="vid mobile" autoPlay={true} loop muted autoplay playsinline>
                        <source src={TraxUniqueBrandVideo} type="video/mp4" />
                    </video>					
                </Col>
            </Row>			
        </Container>
        <svg width="155" height="155" viewBox="0 0 155 155" fill="none" xmlns="http://www.w3.org/2000/svg" className="svg-star-bsub">
            <path d="M60.25 42.1073L56.4872 24.7924L65.6047 39.9857C75.2666 56.0865 94.116 64.2378 112.465 60.2503L129.78 56.4875L114.587 65.6049C98.4859 75.2668 90.3345 94.1162 94.3221 112.465L98.0849 129.78L88.9674 114.587C79.3055 98.4861 60.4561 90.3347 42.1071 94.3223L24.7921 98.0851L39.9855 88.9676C56.0862 79.3057 64.2376 60.4563 60.25 42.1073Z" fill="#FEF5E5" stroke="black"/>
        </svg>

    </section>

  )
}

export default TraxUniqueBrand
