import React from "react"
import TraxStar1 from "../../icon/traxStar1"
import TraxLogo from "../../icon/traxLogo"
import TraxStar2 from "../../icon/traxStar2"
import TraxVideo from "../../../images/case-studies/trax-mobile-app/cowboy-assistance.mp4"
import { Col, Container, Row } from "react-bootstrap"

const TraxInfo = () => {
  return (
    <section className="cs-info position-relative">
        <Container className="position-relative">
            <TraxStar1 />        
            <Row className="pb-md-5 pb-0">
                <Col lg={6}>
                    <h1 className="cs-title px-0 u-section mb-4">Everything Bikes with TRAX!</h1>
                    <p className="cs-sub-text mb-4">TRAX is an e-commerce app that targets e-bike enthusiasts to purchase e-bikes and customize them according to their preferences.</p>
                    <Col md={8} className="details-mini d-flex justify-content-between px-0">
                        <div className="stats-info">
                            <p className="cs-sub-text st-title mb-0">Duration</p>
                            <p className="st-data mb-0">3 Months</p>
                        </div>
                        <div className="stats-info">
                            <p className="cs-sub-text st-title mb-0">Services</p>
                            <p className="st-data mb-0">UX/UI</p>
                        </div>
                        <div className="stats-info">
                            <p className="cs-sub-text st-title mb-0">Platform</p>
                            <p className="st-data mb-0">iOS, Android</p>
                        </div>												
                    </Col>                
                </Col>
                <Col lg={5} className="offset-lg-1 text-lg-right mt-lg-0 mt-md-5 mt-5">
                    <div className="position-relative">
                        <TraxLogo />
                        <TraxStar2 />
                    </div>
                </Col>
            </Row>
			<div className="cs-brief mt-5">
				<Row className="align-items-center">
					<Col lg={5} className="pe-lg-5">
						<div className="info-gif position-relative">
							<video width="490" height="276" className="vid" autoPlay={true} loop muted autoplay playsinline>
								<source src={TraxVideo} type="video/mp4" />
							</video>
							<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="svg-info-gif-top">
								<path d="M9 0L10.2728 7.72721L18 9L10.2728 10.2728L9 18L7.72721 10.2728L0 9L7.72721 7.72721L9 0Z" fill="#FDFDFD"/>
							</svg>
							<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" className="svg-info-gif-bottom">
								<path d="M15.9068 14.4969L15.5865 14.6581L15.6364 15.0131L16.9193 24.1382L12.7775 15.9067L12.6164 15.5863L12.2613 15.6363L3.13624 16.9191L11.3678 12.7774L11.6881 12.6162L11.6382 12.2611L10.3553 3.13607L14.4971 11.3676L14.6582 11.6879L15.0133 11.638L24.1383 10.3551L15.9068 14.4969Z" stroke="#FE5F1A"/>
							</svg>
						</div>
					</Col>
					<Col lg={7} className="position-relative">
						<svg width="18.5" height="18.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="svg-star-description-top">
							<path d="M14.0885 6.34292L14.1936 6.16073L14.1525 6.36702C13.5426 9.42425 14.9491 12.5301 17.6493 14.0883L17.8315 14.1935L17.6252 14.1523C14.5679 13.5424 11.4621 14.949 9.90383 17.6491L9.79869 17.8313L9.83984 17.625C10.4497 14.5678 9.04317 11.4619 6.34306 9.9037L6.16086 9.79856L6.36716 9.83971C9.42439 10.4496 12.5303 9.04303 14.0885 6.34292Z" fill="#FEF5E5" stroke="black"/>
						</svg>

						<p className="cs-sub-text mt-lg-0 mt-4">We designed TRAX as a dream come true application for bike enthusiasts who prefer owning and riding bikes that reflect their personalities. From an array of brands all over the world, they get to purchase e-bikes and customize the components according to their taste on TRAX.</p>
					</Col>
				</Row>
			</div>            
        </Container>
    </section>
  )
}

export default TraxInfo
