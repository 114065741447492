import React from "react";

function TraxStar1() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      fill="none"
      className="star-top-left"
      viewBox="0 0 60 60"
    >
      <path
        fill="#FFD223"
        stroke="#000"
        d="M34.277 10.767l3.127-6.91-.746 7.548a22.658 22.658 0 0013.205 22.871l6.91 3.128-7.548-.746a22.658 22.658 0 00-22.871 13.204l-3.128 6.91.746-7.548a22.658 22.658 0 00-13.205-22.87l-6.91-3.128 7.548.746a22.658 22.658 0 0022.872-13.205z"
      ></path>
    </svg>
  );
}

export default TraxStar1;