import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const TraxFullWidth1 = () => {
  return (
    <section className="bike-shop-full-width">
      <Container fluid>
        <Row>
          <Col lg={12} className="px-0">
            <div className="full-width-1 image-mobile">
              <StaticImage src="../../../images/case-studies/trax-mobile-app/bike-shop-app-full-wdth-1.png" alt="Trax Mobile App WowMakers Case study" placeholder="blurred" className="img-fluid full-width" width={1440} height={796}/>
              <div className="bike-shop-animation-1 d-flex align-items-center justify-content-center">
                <StaticImage src="../../../images/case-studies/trax-mobile-app/bike-shop-gif-1.png" alt="Trax Mobile App WowMakers Case study" placeholder="blurred" className="img-fluid cs-image-min-width" width={284} height={572}/>            
              </div>
            </div>
          </Col>
        </Row>
      </Container>
	</section>    
  )
}

export default TraxFullWidth1
