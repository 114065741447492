import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const TraxFullWidth2 = () => {
  return (
    <section className="bike-shop-full-width">
      <Container fluid>
        <Row>
          <Col lg={12} className="px-0">
            <div className="full-width-1">
              <StaticImage src="../../../images/case-studies/trax-mobile-app/bike-shop-app-full-wdth-2.png" alt="Trax Mobile App WowMakers Case study" placeholder="blurred" className="img-fluid fullwidth d-md-block d-none" width={1440} height={796}/>
              <StaticImage src="../../../images/case-studies/trax-mobile-app/bike-shop-app-full-wdth-2-mobile.png" alt="Trax Mobile App WowMakers Case study" placeholder="blurred" className="img-fluid fullwidth d-md-none" width={450} height={926}/>
            </div>
          </Col>
        </Row>
      </Container>
	</section>    
  )
}

export default TraxFullWidth2
