import React from "react";

function TraxLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="362"
      height="97"
      fill="none"
      className="svg-cl-logo"
      viewBox="0 0 362 97"
    >
      <path
        fill="#FF6A29"
        stroke="#FA753C"
        strokeWidth="1.166"
        d="M134.128 48.5L86.602 91.216 44.703 53.558l10.384-9.333L67.11 55.032l.39.35.39-.35 6.784-6.098.483-.434-.483-.434L48.788 24.8l-.39-.35-.39.35-25.886 23.266-.482.434.482.434 31.032 27.891-10.384 9.333L.872 48.5 48.398 5.784 67.11 22.602l.39.35.39-.35L86.602 5.784 134.128 48.5zM78.366 31.852l-.482.433.482.434L95.924 48.5 78.366 64.281l-.482.434.482.433 7.846 7.052.39.35.39-.35 25.886-23.267.482-.433-.482-.434L86.992 24.8l-.39-.35-.39.35-7.846 7.052z"
      ></path>
      <path
        fill="#FF6A29"
        d="M179.71 87c-3.3 0-5.94-.99-7.92-2.97-1.907-1.98-2.86-4.62-2.86-7.92V42.34h-14.96v-9.57h14.96V14.84h11.33v17.93h16.17v9.57h-16.17v31.79c0 2.2 1.027 3.3 3.08 3.3h11.33V87h-14.96zm26.731 0V32.77h11.11v6.38h1.76c.88-2.273 2.273-3.923 4.18-4.95 1.98-1.1 4.4-1.65 7.26-1.65h6.49v10.23h-6.93c-3.667 0-6.673 1.027-9.02 3.08-2.347 1.98-3.52 5.06-3.52 9.24V87h-11.33zm54.565 1.54c-3.887 0-7.37-.66-10.45-1.98-3.007-1.32-5.427-3.263-7.26-5.83-1.76-2.567-2.64-5.683-2.64-9.35 0-3.74.88-6.82 2.64-9.24 1.833-2.493 4.29-4.363 7.37-5.61 3.153-1.247 6.71-1.87 10.67-1.87h16.5v-3.52c0-3.153-.953-5.683-2.86-7.59s-4.84-2.86-8.8-2.86c-3.887 0-6.857.917-8.91 2.75s-3.41 4.217-4.07 7.15l-10.56-3.41a22.837 22.837 0 014.18-7.92c1.98-2.42 4.583-4.363 7.81-5.83 3.227-1.467 7.113-2.2 11.66-2.2 7.04 0 12.577 1.797 16.61 5.39 4.033 3.593 6.05 8.69 6.05 15.29v22.33c0 2.2 1.027 3.3 3.08 3.3h4.62V87h-8.47c-2.567 0-4.657-.66-6.27-1.98-1.613-1.32-2.42-3.117-2.42-5.39v-.33h-1.65c-.587 1.1-1.467 2.383-2.64 3.85s-2.897 2.75-5.17 3.85c-2.273 1.027-5.28 1.54-9.02 1.54zm1.65-9.35c4.547 0 8.213-1.283 11-3.85 2.787-2.64 4.18-6.233 4.18-10.78v-1.1h-15.84c-3.007 0-5.427.66-7.26 1.98-1.833 1.247-2.75 3.117-2.75 5.61 0 2.493.953 4.473 2.86 5.94 1.907 1.467 4.51 2.2 7.81 2.2zM299.742 87l20.68-27.39-20.24-26.84h13.31l14.19 19.47h1.76l14.19-19.47h13.42l-20.35 26.84L357.382 87h-13.53l-14.41-19.91h-1.76L313.272 87h-13.53z"
      ></path>
    </svg>
  );
}

export default TraxLogo;