import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const TraxMain = () => {
  return (
    <div className="cs-header d-flex align-items-end">
        <Container>
            <Row className="justify-content-center">
                <Col lg={7} md={10} className="position-relative">
                    <div className="hero-image text-center">
                        <StaticImage src="../../../images/case-studies/trax-mobile-app/bike-shop-app-hero-img.png" alt="Trax Mobile App WowMakers Case study Header" placeholder="blurred" className="img-fluid" width={769} height={613}/>
                    </div>
                </Col>
            </Row>
        </Container>       
    </div>
  )
}

export default TraxMain
